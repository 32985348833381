console.clear()
window.scrollTo(0,0)


import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'dat.gui'
import {gsap} from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);


/**
 * Base
 */

let sceneReady = false
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
const textureLoader = new THREE.TextureLoader()
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

/**
 * Background
 */
const background = textureLoader.load('img/bg.jpg')
scene.background = background

/**
 * Update all materials function
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMapIntensity = 5
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
            //child.material.wireframe = true
        }
    })
}




let boxGroup, lid, leafletFront, leafletBack, tag

gltfLoader.load('models/Club Platinum2.glb',
(gltf) =>
{
    boxGroup = gltf.scene
    
    lid = gltf.scene.children.find((child) => child.name === 'lid')
    leafletFront = gltf.scene.children.find((child) => child.name === 'ticket_front')
    leafletBack = gltf.scene.children.find((child) => child.name === 'ticket_back')
    tag = gltf.scene.children.find((child) => child.name === 'tag')
    //water.material = waterMaterial
    boxGroup.position.set(0,0,0)
    scene.add(boxGroup)
    sceneReady = true

    updateAllMaterials()
    gsapAnimation()
})
/**
 * GSAP
 */
function gsapAnimation() {

  const boxTimeline = gsap.timeline({
    immediateRender: false,
    scrollTrigger: {
      trigger: "#section_two",
      scrub: true,
      pin: false,
      markers: false,
      }
    })

    boxTimeline.to(boxGroup.rotation, {
      y: - (Math.PI / 8) * 5.7
    },0)
    boxTimeline.to(boxGroup.position, {
      y: boxGroup.position.y - 0.4
    },0)

  /* gsap.to(boxGroup.rotation, {
    immediateRender: false,
    scrollTrigger: {
      trigger: "#section_two", 
      scrub: true,
      pin: false,
      toggleClass: {targets: "#sec-two-half", className: "active"},
      markers: false
    }, // start the animation when "#section_three" enters the viewport (once)
    y: - (Math.PI / 8) * 5.7
  }) */

  gsap.to(lid.rotation, {
    scrollTrigger: {
      trigger: "#section_three", 
      scrub: true,
      pin: false,
      toggleClass: {targets: "#sec-three-half", className: "active"},
      markers: false
    }, // start the animation when "#section_two" enters the viewport (once)
    x: (Math.PI / 4) * 4.2
  })

  const leafletTimeline = gsap.timeline({
    immediateRender: false,
    scrollTrigger: {
      trigger: "#section_four",
      scrub: true,
      pin: false,
      markers: false,
      }
    })

leafletTimeline.to(leafletFront.position, {
  y: leafletFront.position.y + 1.45
}, 0 )
leafletTimeline.to(leafletBack.position, {
  y: leafletBack.position.y + 1.45
}, 0)
  
const leafletTimelineOpen = gsap.timeline({
  immediateRender: false,
  scrollTrigger: {
    trigger: "#section_five",
    
    scrub: true,
    pin: false,
    markers: false,
    }
  })

  leafletTimelineOpen.to(leafletFront.rotation, {
    x: (Math.PI / 4) * 0.1
  })

  gsap.to(tag.position, {
    scrollTrigger: {
      trigger: "#section_six", 
      scrub: true,
      pin: false,
     
      markers: false
    }, // start the animation when "#section_two" enters the viewport (once)
    y: tag.position.y + 1
  })
}

/**
 * Lights
 */

const ambientLight = new THREE.AmbientLight('#ffffff', 3)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight('#ffffff', 6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.normalBias = 0.02
directionalLight.position.set(-8, 9, 0.4)
scene.add(directionalLight)

gui.add(directionalLight.position, 'x').min(-30).max(30).step(0.1)
gui.add(directionalLight.position, 'y').min(2).max(20).step(0.1)
gui.add(directionalLight.position, 'z').min(-30).max(30).step(0.1)
gui.add(directionalLight, 'intensity').min(0).max(200).step(1).name('sunlightIntensity')

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 3, 4)
camera.lookAt(new THREE.Vector3(0,0.4,0))

scene.add(camera)

// Controls
 //const controls = new OrbitControls(camera, canvas)
//controls.enableDamping = true 

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    //controls.update()
    //boxGroup.rotation.y += 0.001
if (sceneReady === true){
  //boxGroup.rotation.y += 0.003
}

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()